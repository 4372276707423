<!--
 * @Author: your name
 * @Date: 2021-04-15 10:16:53
 * @LastEditTime: 2021-04-20 11:06:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /beefintech-official-homepage/src/views/home/Index.vue
-->
<template>
  <div class="home">
    <div class="banner_bar">
      <!-- autoplay -->
      <a-carousel effect="fade" autoplay arrows dots-class="slick-dots slick-thumb">
        <a slot="customPaging" class="custom_paging">
          <div></div>
        </a>
        <div class="carousel_item banner1">
          <!-- <div class="banner_info">
            <div class="title">AI智能機器人3.1發佈</div>
            <div class="sub_title">智能計劃書 自動續期提醒</div>
            <div class="button_bar">
              <a-button class="button">查看詳情</a-button>
            </div>
          </div> -->
        </div>
        <div class="carousel_item banner2"></div>
        <div class="carousel_item banner3"></div>
        <div class="carousel_item banner4"></div>
        <div class="carousel_item banner5"></div>
      </a-carousel>
    </div>
    <div class="nav primary_width">
      <div>SaaS不僅僅只是工具,更是行業的最佳解決方案</div>
      <div>了解我們的保險展業SAAS工具</div>
      <div>
        SaaS全称Software-as-a
        Service，即依托网络为企业提供软件服务。以技术与服务为核心，让所有企业从中获利。
      </div>
    </div>
    <div class="chart primary_width"></div>
    <div class="product_model primary_width">
      <div class="inner">
        <div v-for="(item, idx) in productModelList" :key="idx">
          <img :src="item.icon" :style="item.style" />
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="flow primary_width">
      <div class="inner">
        <div v-for="(item, idx) in flowList" :key="idx">
          <a
            v-if="!!item.id"
            :class="['title', flowId == item.id ? 'is_active' : '']"
            :href="item.href"
          >
            {{ item.text }}
          </a>
          <div class="pointer" v-else>
            <img src="@/assets/images/home/74@2x.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- 拓客 -->
    <div class="expand_customer primary_width" id="expand_customer">
      <div class="inner">
        <img
          class="customer_detail"
          src="@/assets/images/home/customer_detail.png"
        />
        <div class="container">
          <img src="@/assets/images/home/1.svg" />
          <div>拓客</div>
          <div>發掘客戶購買意向，提高銷售成交率</div>
          <div>
            <span></span>
            精準營銷：獲取客戶閱讀偏好，空閒時間
          </div>
          <div>
            <span></span>
            裂變效應：通過客戶社交關係網拓展二度人脈
          </div>
        </div>
      </div>
    </div>
    <!-- FNA -->
    <div class="fna primary_width" id="fna">
      <div class="inner">
        <img src="@/assets/images/home/fna.png" />
        <div class="container">
          <img src="@/assets/images/home/2.svg" style="" />
          <div>FNA</div>
          <div>
            財務安全需求分析系統，大數據分析工具推薦<br />最適合客戶的產品
          </div>
          <div>
            <span></span>
            風險把控：全流程記錄客戶財務狀況並自動生成簽名報表
          </div>
          <div>
            <span></span>
            符合監管：產品矩陣智能推薦保險產品
          </div>
        </div>
      </div>
    </div>
    <!-- 計劃書 -->
    <div class="business_plan primary_width" id="business_plan">
      <div class="inner">
        <img
          class="business_plan_img"
          src="@/assets/images/home/business_plan.png"
        />
        <div class="container">
          <img src="@/assets/images/home/3.svg" />
          <div>計劃書</div>
          <div>極速生成官方計劃書，成交更有信心</div>
          <div>
            <span></span>
            不受時間空間限制，5分鐘計劃書生成
          </div>
          <div>
            <span></span>
            機器人自動合規化處理敏感信息
          </div>
          <div>
            <span></span>
            計劃書文檔一鍵分享，提升效率
          </div>
        </div>
      </div>
    </div>
    <!-- 預約 -->
    <div class="reservation primary_width" id="reservation">
      <div class="inner">
        <img src="@/assets/images/home/reservation.png" />
        <div class="container">
          <img src="@/assets/images/home/2.svg" style="" />
          <div>預約</div>
          <div>快速生成預約表，自動導出保險公司投保書</div>
          <div>
            <span></span>
            圖像識別身份證信息，過往投保記錄一鍵同步
          </div>
          <div>
            <span></span>
            投保人可自行錄入投保信息並同步顧問
          </div>
          <div>
            <span></span>
            投保信息聯動後台系統，簽單安排一步到位
          </div>
        </div>
      </div>
    </div>
    <!-- 核保 -->
    <div class="underwriting primary_width" id="underwriting">
      <div class="inner">
        <img
          class="underwriting_img"
          src="@/assets/images/home/underwriting.png"
        />
        <div class="container">
          <img src="@/assets/images/home/4.svg" />
          <div>核保</div>
          <div>核保狀態實時同步，Pending事項一鍵上傳</div>
          <div>
            <span></span>
            Timeline及時更新，獲悉保單每一個批核節點
          </div>
          <div>
            <span></span>
            APP隨時隨地補充Pending所需文件
          </div>
        </div>
      </div>
    </div>
    <!-- 保單 -->
    <div class="insurance_policy primary_width" id="insurance_policy">
      <div class="inner">
        <img src="@/assets/images/home/insurance_policy.png" />
        <div class="container">
          <img src="@/assets/images/home/2.svg" style="" />
          <div>保單</div>
          <div>保單詳情隨時查詢，開啟掌上新便利</div>
          <div>保單概要</div>
          <div>投/受保人信息</div>
          <div>繳費信息</div>
          <div>保單歷史附件</div>
        </div>
      </div>
    </div>
    <!-- 佣金结算 -->
    <div class="commission_settlement primary_width" id="commission_settlement">
      <div class="inner">
        <img
          class="commission_settlement_img"
          src="@/assets/images/home/commission_settlement.png"
        />
        <div class="container">
          <img src="@/assets/images/home/5.svg" />
          <div>佣金結算</div>
          <div>全流程佣金結算系統，智能識別保險公司賬單</div>
          <div>
            <span></span>
            支持多類型合約模板
          </div>
          <div>
            <span></span>
            保險公司Monthly Statement一鍵上傳並生成實收金額
          </div>
          <div>
            <span></span>
            自動結算多層渠道佣金
          </div>
          <div>
            <span></span>
            財務報表導出
          </div>
        </div>
      </div>
    </div>
    <!-- 續期 -->
    <div class="renew primary_width" id="renew">
      <div class="inner">
        <img src="@/assets/images/home/renew.png" />
        <div class="container">
          <img src="@/assets/images/home/2.svg" style="" />
          <div>續期</div>
          <div>系统自动推送保费到期消息，續期提醒智能化</div>
          <div>APP/郵件/短信定制化推送續費消息</div>
          <div>AI機器人自動下載續費文檔並更新繳費狀態</div>
        </div>
      </div>
    </div>
    <!-- 成就 -->
    <div class="achievement">
      <div class="title">多年沉澱 只為幫助我們的客戶成功</div>
      <div class="inner">
        <div v-for="(item, idx) in achievementList" :key="idx" class="item">
          <div class="img_box">
            <img :src="item.icon" :style="item.iconStyle" />
          </div>
          <div class="text">{{ item.text }}</div>
          <div class="content">
            <span>{{ item.content }}</span>
            <span>{{ item.unit }}</span>
          </div>
        </div>
      </div>
      <div class="sub_title">自2017年以來，BeeFintech</div>
      <div class="progress">
        <img
          src="@/assets/images/home/椭圆563.png"
          width="224px"
          height="224px"
          style="margin-right: 314px"
        />
        <img
          src="@/assets/images/home/椭圆564.png"
          width="224px"
          height="224px"
        />
      </div>
    </div>
    <!-- 合作夥伴 -->
    <!-- <div class="partners primary_width">
      <div class="inner">
        <div class="title">業界認可 香港保險持牌機構的首選</div>
        <div class="desc">部分合作伙伴，排名不分先後</div>
        <a-row :gutter="[24, 24]">
          <a-col :span="6" v-for="(item, idx) in partnerList" :key="idx">
            <div class="item">
              <img :src="item.icon" :style="item.style" />
            </div>
          </a-col>
        </a-row>
      </div>
    </div> -->
    <!-- 底部 -->
    <div class="footer primary_width">
      <div class="inner">
        <div class="title">準備好開始使用了嗎？</div>
        <div class="desc">聯系我們，一對一客戶服務，專業產品介紹</div>
        <div class="submit">
          <a-input placeholder="請輸入您的聯系電話" size="large">
            <div slot="addonAfter">提 交</div>
          </a-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const productModelList = [
  {
    icon: require("@/assets/images/home/ios.png"),
    title: "macOS",
    style: { width: "26px", height: "32px" },
  },
  {
    icon: require("@/assets/images/home/windows.png"),
    title: "Windows",
    style: { width: "29", height: "30px" },
  },
  {
    icon: require("@/assets/images/home/ios.png"),
    title: "iOS",
    style: { width: "26px", height: "32px" },
  },
  {
    icon: require("@/assets/images/home/android.png"),
    title: "Android",
    style: { width: "28px", height: "32px" },
  },
];
const flowList = [
  { text: "拓客", id: 1, href: "#expand_customer" },
  { text: "icon" },
  { text: "FNA", id: 2, href: "#fna" },
  { text: "icon" },
  { text: "出計劃書", id: 3, href: "#business_plan" },
  { text: "icon" },
  { text: "預約", id: 4, href: "#reservation" },
  { text: "icon" },
  { text: "核保", id: 5, href: "#underwriting" },
  { text: "icon" },
  { text: "保單", id: 6, href: "#insurance_policy" },
  { text: "icon" },
  { text: "佣金結算", id: 7, href: "#commission_settlement" },
  { text: "icon" },
  { text: "續期", id: 8, href: "#renew" },
];
const achievementList = [
  {
    text: "累計AI計劃書制作超過",
    content: "10",
    unit: "萬+",
    icon: require("@/assets/images/home/形状 17@2x.png"),
    iconStyle: { width: "55px", height: "59px" },
  },
  {
    text: "助力客戶達成保單超過",
    content: "15",
    unit: "萬+",
    icon: require("@/assets/images/home/形状 16@2x.png"),
    iconStyle: { width: "64px", height: "64px" },
  },
  {
    text: "助力佣金自動結算",
    content: "25",
    unit: "萬+",
    icon: require("@/assets/images/home/形状 18@2x.png"),
    iconStyle: { width: "55px", height: "60px" },
  },
  {
    text: "累計客戶服務",
    content: "18240",
    unit: "Hr+",
    icon: require("@/assets/images/home/形状 19@2x.png"),
    iconStyle: { width: "55px", height: "59px" },
  },
  {
    text: "系統更新迭代",
    content: "100",
    unit: "+",
    icon: require("@/assets/images/home/形状 553@2x.png"),
    iconStyle: { width: "59px", height: "60px" },
  },
];
const partnerList = [
  {
    icon: require("@/assets/images/home/logo_AmberHill.png"),
    style: { width: "161px", height: "64px" },
  },
  {
    icon: require("@/assets/images/home/logo_OTC.png"),
    style: { width: "117px", height: "66px" },
  },
  {
    icon: require("@/assets/images/home/logo_CFSG.png"),
    style: { width: "123px", height: "108px" },
  },
  {
    icon: require("@/assets/images/home/logo_IFA.png"),
    style: { width: "185px", height: "28px" },
  },
  {
    icon: require("@/assets/images/home/logo_CMG.png"),
    style: { width: "188px", height: "81px" },
  },
  {
    icon: require("@/assets/images/home/logo_FRONTIER.png"),
    style: { width: "133px", height: "95px" },
  },
  {
    icon: require("@/assets/images/home/logo_MAJESTY.png"),
    style: { width: "207px", height: "45px" },
  },
  {
    icon: require("@/assets/images/home/logo_CBF.png"),
    style: { width: "186px", height: "41px" },
  },
  {
    icon: require("@/assets/images/home/logo_TAIPING.png"),
    style: { width: "191px", height: "55px" },
  },
];
export default {
  name: "Home",
  data() {
    return {
      productModelList,
      flowList,
      flowId: 1,
      achievementList,
      partnerList,
    };
  },
};
</script>
<style lang="less" scoped>
.primary_width {
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.bg_image {
  background-position: 50% 50%;
  background-size: cover;
  width: 1920px;
  height: 100%;
}
.home {
  .banner_bar {
    width: 100%;
    height: 700px;

    .carousel_item {
      background-position: 50% 50%;
      background-size: cover;
      width: 1920px;
      height: 700px;
      .banner_info {
        width: 1200px;
        height: 100%;
        margin: auto;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .title {
          font-size: 60px;
          font-weight: 500;
          color: #ffffff;
          line-height: 60px;
          margin-bottom: 56px;
        }
        .sub_title {
          font-size: 26px;
          font-weight: 500;
          color: #cfd2d9;
          margin-bottom: 92px;
        }
        .button_bar {
          .button {
            width: 228px;
            height: 81px;
            background: #00d4af;
            border-radius: 40px;
            border: none;
            font-size: 30px;
            font-weight: 500;
            color: #fffefe;
          }
        }
      }
    }
    .banner1 {
      background-image: url(~@/assets/images/home/banner/1-1.png);
    }
    .banner2 {
      background-image: url(~@/assets/images/home/banner/2-1.png);
    }
    .banner3 {
      background-image: url(~@/assets/images/home/banner/3-1.png);
    }
    .banner4 {
      background-image: url(~@/assets/images/home/banner/4-1.png);
    }
    .banner5 {
      background-image: url(~@/assets/images/home/banner/5-1.png);
    }
  }
  .nav {
    text-align: center;
    padding-top: 122px;
    & > div:nth-child(1) {
      font-size: 20px;
      font-family: PingFang TC;
      font-weight: 400;
      color: #999999;
      margin-bottom: 22px;
    }
    & > div:nth-child(2) {
      font-size: 40px;
      font-family: PingFang TC;
      font-weight: 600;
      color: #2a2e42;
      margin-bottom: 28px;
    }
    & > div:nth-child(3) {
      font-size: 16px;
      font-family: Songti TC;
      font-weight: 400;
      color: #83a2cc;
      margin-bottom: 46px;
    }
  }
  .chart {
    // overflow: hidden;
    height: 636px;
    background-image: url(~@/assets/images/home/chart.png);
    background-position: 50% 50%;
    background-size: cover;
  }
  .product_model {
    height: 130px;
    .inner {
      width: 1200px;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: space-around;
      padding-top: 40px;
      font-size: 18px;
      font-family: PingFang TC;
      font-weight: 400;
      color: #666666;
      img {
        margin-right: 12px;
      }
    }
  }
  .flow {
    margin-bottom: 70px;
    .inner {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      box-shadow: 0px 1px 3px 0px rgba(48, 65, 100, 0.08);
      border-radius: 45px;
      padding: 0 50px;
      & > div {
        .title {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 0.8);
          height: 90px;
          line-height: 90px;
          display: inline-block;
        }
        .title.is_active {
          color: #00d4af;
          border-bottom: 4px solid #00d4af;
        }
        .pointer {
          height: 90px;
          line-height: 90px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .expand_customer {
    height: 570px;
    background-image: url(~@/assets/images/home/expand_customer.png);
    background-position: 50% 50%;
    background-size: cover;
    .inner {
      width: 1200px;
      margin: auto;
      height: 100%;
      position: relative;
      img.customer_detail {
        position: absolute;
        left: 0;
        top: 30px;
        width: 404px;
        height: 695px;
      }
      .container {
        padding-top: 100px;
        margin-left: 508px;
        & > img:nth-child(1) {
          width: 65px;
          height: 44px;
          margin-bottom: 52px;
        }
        & > div:nth-child(2) {
          font-size: 60px;
          line-height: 60px;
          font-family: PingFang TC;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 28px;
        }
        & > div:nth-child(3) {
          font-size: 34px;
          line-height: 34px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 40px;
        }
        & > div:nth-child(4),
        & > div:nth-child(5) {
          font-size: 16px;
          line-height: 16px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 14px;
          & > span {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #ffffff;
            border-radius: 50%;
            margin-right: 22px;
          }
        }
      }
    }
  }
  .fna {
    height: 740px;
    .inner {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      & > img {
        position: absolute;
        right: -15px;
        top: 106px;
        width: 535px;
        height: 525px;
      }
      .container {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        & > img:nth-child(1) {
          width: 65px;
          height: 44px;
          margin-bottom: 40px;
        }
        & > div:nth-child(2) {
          font-size: 60px;
          line-height: 60px;
          font-family: PingFang TC;
          font-weight: 500;
          color: #2a2e42;
          margin-bottom: 30px;
        }
        & > div:nth-child(3) {
          font-size: 34px;
          line-height: 36px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #0d68e1;
          margin-bottom: 40px;
        }
        & > div:nth-child(4),
        & > div:nth-child(5) {
          font-size: 16px;
          line-height: 16px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 14px;
          & > span {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #1c64c4;
            border-radius: 50%;
            margin-right: 22px;
          }
        }
      }
    }
  }
  .business_plan {
    height: 568px;
    background-image: url(~@/assets/images/home/bg_business_plan.png);
    background-position: 50% 50%;
    background-size: cover;
    .inner {
      width: 1200px;
      margin: auto;
      height: 100%;
      position: relative;
      img.business_plan_img {
        position: absolute;
        left: 0;
        top: 30px;
        width: 404px;
        height: 695px;
      }
      .container {
        padding-top: 108px;
        margin-left: 508px;
        & > img:nth-child(1) {
          width: 65px;
          height: 44px;
          margin-bottom: 50px;
        }
        & > div:nth-child(2) {
          font-size: 60px;
          line-height: 60px;
          font-family: PingFang TC;
          font-weight: 500;
          color: #f7f7f7;
          margin-bottom: 28px;
        }
        & > div:nth-child(3) {
          font-size: 34px;
          line-height: 34px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #f7f7f7;
          margin-bottom: 45px;
        }
        & > div:nth-child(4),
        & > div:nth-child(5),
        & > div:nth-child(6) {
          font-size: 16px;
          line-height: 16px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #f7f7f7;
          margin-bottom: 14px;
          & > span {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #ffffff;
            border-radius: 50%;
            margin-right: 22px;
          }
        }
      }
    }
  }
  .reservation {
    height: 740px;
    .inner {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      & > img {
        position: absolute;
        right: -125px;
        top: 119px;
        width: 567px;
        height: 522px;
      }
      .container {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        & > img:nth-child(1) {
          width: 65px;
          height: 44px;
          margin-bottom: 54px;
        }
        & > div:nth-child(2) {
          font-size: 60px;
          line-height: 60px;
          font-family: PingFang TC;
          font-weight: 500;
          color: #2a2e42;
          margin-bottom: 18px;
        }
        & > div:nth-child(3) {
          font-size: 34px;
          line-height: 36px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #0d68e1;
          margin-bottom: 60px;
        }
        & > div:nth-child(4),
        & > div:nth-child(5),
        & > div:nth-child(6) {
          font-size: 16px;
          line-height: 16px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 14px;
          & > span {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #1c64c4;
            border-radius: 50%;
            margin-right: 22px;
          }
        }
      }
    }
  }
  .underwriting {
    height: 570px;
    background-image: url(~@/assets/images/home/bg_underwriting.png);
    background-position: 50% 50%;
    background-size: cover;
    .inner {
      width: 1200px;
      margin: auto;
      height: 100%;
      position: relative;
      img.underwriting_img {
        position: absolute;
        left: -88px;
        top: 149px;
        width: 561px;
        height: 453px;
      }
      .container {
        padding-top: 146px;
        margin-left: 643px;
        & > img:nth-child(1) {
          width: 65px;
          height: 44px;
          margin-bottom: 30px;
        }
        & > div:nth-child(2) {
          font-size: 60px;
          line-height: 60px;
          font-family: PingFang TC;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 28px;
        }
        & > div:nth-child(3) {
          font-size: 34px;
          line-height: 34px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 50px;
        }
        & > div:nth-child(4),
        & > div:nth-child(5) {
          font-size: 16px;
          line-height: 16px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 14px;
          & > span {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #ffffff;
            border-radius: 50%;
            margin-right: 22px;
          }
        }
      }
    }
  }
  .insurance_policy {
    height: 740px;
    .inner {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      & > img {
        position: absolute;
        right: -95px;
        top: 117px;
        width: 634px;
        height: 520px;
      }
      .container {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        & > img:nth-child(1) {
          width: 65px;
          height: 44px;
          margin-bottom: 40px;
        }
        & > div:nth-child(2) {
          font-size: 60px;
          line-height: 60px;
          font-family: PingFang TC;
          font-weight: 500;
          color: #2a2e42;
          margin-bottom: 30px;
        }
        & > div:nth-child(3) {
          font-size: 34px;
          line-height: 36px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #0d68e1;
          margin-bottom: 33px;
        }
        & > div:nth-child(4),
        & > div:nth-child(5),
        & > div:nth-child(6),
        & > div:nth-child(7) {
          font-size: 16px;
          line-height: 16px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 14px;
        }
      }
    }
  }
  .commission_settlement {
    height: 570px;
    background-image: url(~@/assets/images/home/bg_commission_settlement.png);
    background-position: 50% 50%;
    .inner {
      width: 1200px;
      margin: auto;
      height: 100%;
      position: relative;
      img.commission_settlement_img {
        position: absolute;
        left: -86px;
        bottom: 0;
        width: 594px;
        height: 470px;
      }
      .container {
        padding-top: 99px;
        margin-left: 560px;
        & > img:nth-child(1) {
          width: 65px;
          height: 44px;
          margin-bottom: 38px;
        }
        & > div:nth-child(2) {
          font-size: 60px;
          line-height: 60px;
          font-family: PingFang TC;
          font-weight: 500;
          color: #f7f7f7;
          margin-bottom: 28px;
        }
        & > div:nth-child(3) {
          font-size: 34px;
          line-height: 34px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #f7f7f7;
          margin-bottom: 60px;
        }
        & > div:nth-child(4),
        & > div:nth-child(5),
        & > div:nth-child(6),
        & > div:nth-child(7) {
          font-size: 16px;
          line-height: 16px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #f7f7f7;
          margin-bottom: 14px;
          & > span {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #f7f7f7;
            border-radius: 50%;
            margin-right: 22px;
          }
        }
      }
    }
  }
  .renew {
    height: 740px;
    .inner {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      & > img {
        position: absolute;
        right: -48px;
        top: 114px;
        width: 435px;
        height: 512px;
      }
      .container {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        & > img:nth-child(1) {
          width: 65px;
          height: 44px;
          margin-bottom: 38px;
        }
        & > div:nth-child(2) {
          font-size: 60px;
          line-height: 60px;
          font-family: PingFang TC;
          font-weight: 500;
          color: #2a2e42;
          margin-bottom: 32px;
        }
        & > div:nth-child(3) {
          font-size: 34px;
          line-height: 36px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #0d68e1;
          margin-bottom: 39px;
        }
        & > div:nth-child(4),
        & > div:nth-child(5) {
          font-size: 16px;
          line-height: 16px;
          font-family: PingFang TC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 14px;
        }
      }
    }
  }
  .achievement {
    height: 905px;
    background: linear-gradient(-45deg, #357cda, #0f69e1);
    min-width: 1200px;
    text-align: center;
    padding-top: 130px;

    .title {
      margin-bottom: 90px;
      font-size: 45px;
      font-family: PingFang TC;
      font-weight: 500;
      color: #ffffff;
      line-height: 45px;
      opacity: 0.9;
    }
    .inner {
      margin-bottom: 80px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      & > .item {
        height: 200px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        .img_box {
          width: 100px;
          height: 66px;
          display: flex;
          justify-content: center;
        }
        .text {
          font-size: 16px;
          font-family: PingFang HK;
          font-weight: 400;
          color: #ffffff;
        }
        .content {
          :first-child {
            font-size: 60px;
            font-family: PingFang TC;
            font-weight: 600;
            color: #ffffff;
            margin-right: 11px;
          }
          :last-child {
            font-size: 24px;
            font-family: PingFang HK;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
    .sub_title {
      margin-bottom: 60px;
      font-size: 30px;
      font-family: PingFang TC;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      opacity: 0.8;
    }
    .progress {
      height: 224px;
      display: flex;
      justify-content: center;
      .left {
        width: 224px;
        height: 224px;
        background-image: url(~@/assets/images/home/椭圆563.png);
        background-position: 50% 50%;
        background-size: cover;
      }
      .right {
        width: 224px;
        height: 224px;
        background-image: url(~@/assets/images/home/椭圆564.png);
        background-position: 50% 50%;
        background-size: cover;
      }
    }
  }
  .partners {
    height: 850px;
    .inner {
      width: 1200px;
      margin: 0 auto;
      padding-top: 130px;
      text-align: center;
      .title {
        font-size: 40px;
        font-family: PingFang TC;
        font-weight: 600;
        color: #2a2e42;
        line-height: 40px;
        margin-bottom: 37px;
      }
      .desc {
        font-size: 24px;
        line-height: 24px;
        font-family: PingFang TC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 80px;
      }
      .item {
        height: 132px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #edeff8;
        border-radius: 5px;
        text-align: center;
        line-height: 132px;
        cursor: pointer;
      }
    }
  }
  .footer {
    height: 348px;
    background: #30343f;
    text-align: center;
    .inner {
      width: 1200px;
      height: 100%;
      margin: auto;
      padding-top: 80px;
      .title {
        font-size: 40px;
        line-height: 40px;
        font-family: PingFang TC;
        font-weight: 500;
        color: #ffffff;
        opacity: 0.9;
        margin-bottom: 23px;
      }
      .desc {
        font-size: 24px;
        line-height: 24px;
        font-family: PingFang TC;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
        margin-bottom: 49px;
      }
      .submit {
        width: 680px;
        margin: 0 auto;
        /deep/.ant-input-group-addon {
          background: #30343f;
          border: 1px solid #ffffff;
          border-left: none;
          border-radius: 0px 5px 5px 0px;
          font-size: 14px;
          font-family: PingFang HK;
          font-weight: 400;
          color: #ffffff;
          width: 148px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style scoped>
/* For demo */
.home .banner_bar .ant-carousel >>> .slick-dots {
  height: auto;
}

.home .banner_bar .ant-carousel >>> .slick-thumb {
  bottom: 16px;
  height: 52px;
}
.home .banner_bar .ant-carousel >>> .slick-thumb li {
  width: 80px;
  height: 100%;
  margin: 0 5px;
}
.home .banner_bar .ant-carousel >>> .slick-thumb li a {
  width: 100%;
  height: 4px;
  margin-top: 24px;
  display: block;
  background: white;
}
.home .banner_bar .ant-carousel >>> .slick-thumb li a div {
  height: 100%;
  background: #3e80d9;
  animation: myfirst 2.8s linear;
  
  -webkit-animation: myfirst 2.8s linear; /* Safari 与 Chrome */
  display: none;
}
.home .banner_bar .ant-carousel >>> .slick-thumb li a:hover div {
  display: block !important;
  animation:none;
  width: 100%;
}
.home .banner_bar .ant-carousel >>> .slick-thumb li.slick-active a div {
  display: block;
}
@keyframes myfirst {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes myfirst /* Safari 与 Chrome */ {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
